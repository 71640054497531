<template>
  <div class="systems grid two-columns">
    <div class="grid-item">
      <InputRadio class="spacing" title="System" :items="optionItems.systemItems" />
      <InputRadio title="System" :items="optionItems.systemDirectionItems" />
    </div>

    <div class="grid-item">
      <InputRadio title="Plattentyp" :items="optionItems.plateTypeItems" />
    </div>
  </div>
</template>

<script>
import InputRadio from "@/components/InputRadio";
import {mapGetters} from "vuex";
export default {
  name: "Systems",
  components: {InputRadio},
  computed: {
    ...mapGetters(['optionItems'])
  }
};
</script>

<style lang="scss">
.systems {
  grid-column-gap: 32px;

  @media (max-width: $width-tablet) {
    grid-template-columns: 1fr !important;
    grid-row-gap: 24px;
  }

  .spacing {
    margin-bottom: 24px;
  }
}
</style>